import {
	PillButton,
	HorizontalRule,
	ToggleSwitch,
	Label,
	FieldDescription,
	Dialog,
	DialogTrigger,
	DialogContent,
	Button,
	CheckboxGroup,
	Checkbox,
} from '@troon/ui';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { produce } from 'solid-js/store';
import { z } from 'zod';
import { IconTroonAccess } from '@troon/icons/troon-access';
import { IconChevronRight } from '@troon/icons/chevron-right';
import { IconFilter } from '@troon/icons/filter';
import { coerceBoolean } from '../../modules/search-store';
import { gql } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { useUser } from '../../providers/user';
import { FavoritesDialog } from '../favorites-dialog';
import { TeeTimeSearchExplainer } from '../tee-time-access-explainer';
import { AuthFlow } from '../../partials/auth/auth';
import type { FragmentType, TeeTimeFacilityFilterFragment } from '../../graphql';
import type { SearchStore, SetSearchStore } from '../../modules/search-store';
import type { ComponentProps } from 'solid-js';

export const teeTimeFilterSchema = z.object({
	access: coerceBoolean().nullish(),
	rewards: coerceBoolean().nullish(),
	accessDeals: coerceBoolean().nullish(),
	facilities: z.array(z.string()).nullish(),
	favorites: coerceBoolean().nullish(),
	filterFacilities: z.union([z.array(z.string()).nullish(), z.string()]),
});

type BaseProps = {
	userHasFavorites?: boolean;
	filters: SearchStore<typeof teeTimeFilterSchema>;
	setFilters: SetSearchStore<typeof teeTimeFilterSchema>;
	withAccessPlusDeals?: boolean;
	withFacilityFilters?: boolean;
};

type CourseProps = BaseProps & {
	courses: Array<FragmentType<typeof TeeTimeCourseFilterFragment>>;
	facilities?: never;
};

type FacilityProps = BaseProps & {
	courses?: never;
	facilities: Array<FragmentType<typeof TeeTimeFacilityFilterFragment>>;
};

export function TeeTimeFilters(props: CourseProps | FacilityProps) {
	const user = useUser();
	const facilities = createFragment(TeeTimeFacilityFilterFragment, props as FacilityProps, 'facilities');
	const courses = createFragment(TeeTimeCourseFilterFragment, props as CourseProps, 'courses');
	const [groupRef, setGroupRef] = createSignal<HTMLElement>();
	const [allRef, setAllRef] = createSignal<HTMLInputElement>();
	const compare = new Intl.Collator('en-US').compare;

	return (
		<div class="flex flex-col gap-6">
			<Show when={props.withFacilityFilters}>
				<TeeTimeSearchExplainer />
				<div class="flex flex-wrap gap-3">
					<PillButton
						aria-pressed={!!props.filters.access}
						onClick={() => props.setFilters(produce((s) => (s.access = !s.access || null)))}
					>
						Troon Access
					</PillButton>

					<PillButton
						aria-pressed={!!props.filters.rewards}
						onClick={() => props.setFilters(produce((s) => (s.rewards = !s.rewards || null)))}
					>
						Troon Rewards
					</PillButton>

					<Switch
						fallback={
							<Dialog key="favorite-filter-auth">
								<DialogTrigger as={PillButton}>Favorites</DialogTrigger>
								<DialogContent width="lg" noPadding closeButton="text-white">
									<AuthFlow />
								</DialogContent>
							</Dialog>
						}
					>
						<Match when={user() && props.userHasFavorites === false}>
							<FavoritesDialog
								facilities={facilities}
								onFavorited={() => props.setFilters(produce((s) => (s.favorites = true)))}
							/>
						</Match>
						<Match when={user()}>
							<PillButton
								aria-pressed={!!props.filters.favorites}
								onClick={() => props.setFilters(produce((s) => (s.favorites = !s.favorites || null)))}
							>
								Favorites
							</PillButton>
						</Match>
					</Switch>
				</div>
			</Show>

			<Show when={props.withAccessPlusDeals}>
				<Show when={props.withFacilityFilters}>
					<HorizontalRule />
				</Show>
				<ToggleSwitch
					checked={!!props.filters.accessDeals}
					reverse
					class="flex-wrap"
					onChange={() => props.setFilters(produce((s) => (s.accessDeals = !s.accessDeals || null)))}
				>
					<Label class="grow">
						<IconTroonAccess class="w-8" /> Featured Offers
					</Label>
					<FieldDescription>
						<p class="mr-16 text-sm text-neutral-800">
							Show tee times more than 25% off the standard rate. Access+ Members only.
						</p>
					</FieldDescription>
				</ToggleSwitch>
			</Show>

			<Show
				when={
					facilities && Array.from(facilities)?.length > 1
						? facilities
						: courses && Array.from(courses)?.length > 1
							? (courses as typeof facilities)
							: false
				}
			>
				{(facilities) => (
					<>
						<HorizontalRule />
						<CheckboxGroup name="facilities">
							<details ref={setGroupRef} class="group/details flex flex-col gap-2">
								<summary class="-mx-2 box-content flex w-full cursor-pointer justify-between gap-1 rounded-md px-2 py-1 hover:bg-brand-100 details-marker:hidden marker:hidden ">
									<Label class="pointer-events-none">Courses</Label>
									<span class="me-1 rotate-90 text-brand transition group-open/details:-rotate-90">
										<IconChevronRight />
									</span>
								</summary>
								<div class="flex flex-col gap-1">
									<Checkbox
										reverse
										ref={setAllRef}
										value="__all__"
										data-indeterminate={allRef()?.indeterminate}
										checked={
											!props.filters.filterFacilities || props.filters.filterFacilities?.length === facilities().length
										}
										onChange={(e) => {
											const checked = e.target.checked;
											props.setFilters(
												produce((s) => {
													s.filterFacilities = checked ? null : [];
												}),
											);
										}}
									>
										<Label class="grow font-normal">All</Label>
									</Checkbox>
									<For each={Array.from(facilities()).sort((a, b) => compare(a.name, b.name))}>
										{(facility) => {
											const filters = () =>
												props.filters.filterFacilities && !Array.isArray(props.filters.filterFacilities)
													? [props.filters.filterFacilities]
													: props.filters.filterFacilities;
											return (
												<Checkbox
													reverse
													value={facility.slug}
													checked={!filters() || (filters()?.indexOf(facility.slug) ?? -1) > -1}
													onChange={(e) => {
														const elements = groupRef()!.querySelectorAll(
															`[name=${e.target.name}]:checked`,
														) as NodeListOf<HTMLInputElement>;
														const checked = Array.from(elements)
															.map((el) => el.value)
															.filter((v) => v !== '__all__');
														const all = checked.length === (facilities()?.length ?? -1);
														const allEl = allRef();
														if (allEl) {
															allEl.indeterminate = !all;
														}
														props.setFilters(
															produce((s) => {
																s.filterFacilities = all ? null : checked;
															}),
														);
													}}
												>
													<Label class="grow font-normal">{facility.name}</Label>
												</Checkbox>
											);
										}}
									</For>
								</div>
							</details>
						</CheckboxGroup>
					</>
				)}
			</Show>
		</div>
	);
}
export function TeeTimeFiltersFAB(props: ComponentProps<typeof TeeTimeFilters>) {
	const [filterDialogOpen, setFilterDialogOpen] = createSignal(false);

	return (
		<Dialog key="tee-time-search-fitlers" open={filterDialogOpen()} onOpenChange={setFilterDialogOpen}>
			<div class="fixed inset-x-0 bottom-4 z-50 flex flex-col items-center">
				<DialogTrigger class="size-fit shadow">
					<IconFilter /> Filter by
				</DialogTrigger>
			</div>
			<DialogContent header="Filter by" headerLevel="h2" height="fit" noPadding>
				<div class="px-4 pb-4 md:px-6 md:pb-6">
					<TeeTimeFilters {...props} />
				</div>
				<div class="sticky inset-x-0 bottom-0 flex border-t border-neutral bg-white p-4 md:p-6">
					<Button onClick={() => setFilterDialogOpen(false)}>View results</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

const TeeTimeFacilityFilterFragment = gql(`fragment TeeTimeFacilityFilter on Facility {
	name
	slug
	...FavoriteFacility
}`);

const TeeTimeCourseFilterFragment = gql(`fragment TeeTimeCourseFilter on Course {
	name
	slug
}`);
