import { trackDeep } from '@solid-primitives/deep';
import { createVisibilityObserver } from '@solid-primitives/intersection-observer';
import { createSignal, createRenderEffect } from 'solid-js';
import { Button, Container } from '@troon/ui';
import { IconEditPencil01 } from '@troon/icons/edit-pencil-01';
import { TeeTimeSearchFields } from '../tee-time-search';
import type { teeTimeSearchSchema } from '../tee-time-search';
import type { JSXElement } from 'solid-js';
import type { SearchStore, SetSearchStore } from '../../modules/search-store';

type Props = {
	filters: SearchStore<typeof teeTimeSearchSchema>;
	hideQueryInput?: boolean;
	onSearchUpdate?: () => void;
	setFilters: SetSearchStore<typeof teeTimeSearchSchema>;
	scrollHeader: JSXElement;
	trackQuery: boolean;
};

export function TeeTimeSearchHeader(props: Props) {
	const [topTarget, setTopTarget] = createSignal<Element>();
	const [showSearch, setShowSearch] = createSignal(false);
	// eslint-disable-next-line solid/reactivity
	const atTop = createVisibilityObserver({ threshold: 0.1 })(topTarget);
	createRenderEffect(() => {
		if (trackDeep(props.filters)) {
			setShowSearch(false);
		}
	});

	return (
		<>
			<div class="pointer-events-none -mb-4 h-4" ref={setTopTarget} />
			<div class="sticky top-0 z-20 border-b border-neutral bg-white py-4">
				<div
					class="overflow-hidden transition-all lg:hidden"
					classList={{
						'max-h-0 opacity-0': atTop() || showSearch() || (props.trackQuery && !props.filters.query),
						'max-h-24 opacity-100': !atTop() && !showSearch() && (!props.trackQuery || !!props.filters.query),
					}}
					onClick={() => setShowSearch(true)}
				>
					<Container class="flex-row justify-between gap-2">
						<div class="grow truncate">{props.scrollHeader}</div>
						<Button appearance="transparent" class="grow-0" onClick={() => setShowSearch(true)}>
							<IconEditPencil01 />
						</Button>
					</Container>
				</div>
				<div
					class="overflow-hidden transition-all"
					classList={{
						'max-h-0 opacity-0 lg:opacity-100 lg:max-h-96':
							!atTop() && !showSearch() && (!props.trackQuery || !!props.filters.query),
						'max-h-96 opacity-100': atTop() || showSearch() || (props.trackQuery && !props.filters.query),
					}}
				>
					<Container class="gap-4">
						<div class="flex flex-row flex-wrap items-stretch xl:flex-nowrap">
							<TeeTimeSearchFields
								hideQueryInput={props.hideQueryInput}
								noButton
								hideShowAllLink
								onSearchUpdate={props.onSearchUpdate}
								filters={props.filters}
								setFilters={props.setFilters}
							/>
						</div>
						<div class="grow lg:hidden" classList={{ hidden: !showSearch() || atTop() }}>
							<Button class="w-full" onClick={() => setShowSearch(false)}>
								Done
							</Button>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}
